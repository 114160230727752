.BackGround{
    z-index: 1;
    background: radial-gradient( rgb(250, 111, 111), rgb(229, 155, 18),rgb(212, 212, 29),rgb(2, 94, 2));
    background-size: 400% 400% ;
    position: absolute;
    left: 0;
    right: 0;
    top:0;
    bottom: 0;
    animation: animate 60s ease infinite;
}
.logo{
    position: absolute;
    left: 0;
    right: 0;
    top:0;
    bottom: 0;
    width: 100%;
    /* height: 100vh; */
    display: block;
    margin-top: auto;
    margin-bottom: auto;

}
.body{
    position: absolute;
    left: 0;
    right: 0;
    top:0;
    bottom: 0;
    overflow: hidden;
}
@keyframes animate{
    0%{
        background-position: 0 50%;
    }
    20%{
        background-position: 50% 0%;
    }
    50%{
        background-position: 40% 10%;
    }
    70%{
        background-position: 80% 0;
    }
    80%{
        background-position: 80% 50%;
    }
    100%{
        background-position: 0 50%;
    }
}